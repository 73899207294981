const fixedTop = () => {
    window.addEventListener('scroll', () => {
        
        const topBar = document.querySelector("#top-navbar");
        let topBarHeight = 0;
        
        if (topBar) {
            topBarHeight = topBar.offsetHeight;
        }

        const siteNavbar = document.querySelector('#site-navbar');

        if (window.scrollY > topBarHeight) {
            siteNavbar.classList.add('fixed-top', 'shadow-sm');
        } else {
            siteNavbar.classList.remove('fixed-top', 'shadow-sm');
        } 
    });
}

export { fixedTop };
