import { fixedTop } from '../functions/fixed-top.js';
import { scrollaJs } from '../functions/scrolla.js';
import { enableTooltip } from '../functions/tooltip.js';
import { popUpForm } from '../functions/pop-up-form.js';

document.addEventListener("DOMContentLoaded", () => {

    fixedTop();
    scrollaJs();
    enableTooltip();
    popUpForm();
});
