const scrollaJs = () => {
    (function ($) {
        'use strict';
        $(document).ready(function () {
    
            $('.animate').scrolla({
                once: true // only once animation play on scroll
            });
        });
    }(jQuery));    
}

export { scrollaJs };
