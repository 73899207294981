export const popUpForm = () => {
    /* - Prevent Multi Submit on WPCF7 forms - */
    let disableSubmit = false;
    jQuery('.wpcf7 input[type="submit"]').on('click', function (e) {

        if (!jQuery('.wpcf7 input[type="submit"]').hasClass('sweetAlert2')) {
            e.preventDefault();

            Swal.fire({
                //title: '¿Estás seguro?',
                text: '¿Deseas confirmar el envío de tus datos para ser contactado por la Universidad de los Andes?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sí, confirmo',
                cancelButtonText: 'No, no confirmo'
            }).then((result) => {
                if (result.isConfirmed) {
                    jQuery('#popup_confirmation').val('Confirmo');
                    jQuery('.wpcf7 input[type="submit"]').addClass('sweetAlert2');
                    jQuery('.wpcf7 input[type="submit"]').click();
                    jQuery(':input[type="submit"]').attr('value', "Enviando");
                    if (disableSubmit === true) {
                        return false;
                    }
                    disableSubmit = true;
                    return true;
                } else {
                    jQuery('#popup_confirmation').val('No confirmo');
                    jQuery('.wpcf7 input[type="submit"]').addClass('sweetAlert2');
                    jQuery('.wpcf7 input[type="submit"]').click();
                    jQuery(':input[type="submit"]').attr('value', "Enviando");
                    if (disableSubmit === true) {
                        return false;
                    }
                    disableSubmit = true;
                    return true;
                }
            });
        }
    });

    const wpcf7Elm = document.querySelector('.wpcf7');
    wpcf7Elm.addEventListener('wpcf7_before_send_mail', function(event) {
        jQuery(':input[type="submit"]').attr('value', "Enviar");
        disableSubmit = false;
    }, false);

    wpcf7Elm.addEventListener('wpcf7invalid', function(event) {
        jQuery(':input[type="submit"]').attr('value', "Solicita Información");
        disableSubmit = false;
    }, false);
}

